import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { SaveControl } from 'shared/ui';
import { useCatalogSearchParams } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { CatalogAvailabilityOrgsTable, CatalogAvailabilityProducts, TypeSubtypeGroupFilters } from 'features';
import { useAvailabilities } from '../../hooks';
import { scrollStyles } from 'widgets/CatalogManager/utils';
import { TypesTable, GroupsTable } from './components';

export const CatalogAvailability: FC = () => {
  const { t } = useTranslation();

  const { catalogId } = useCatalogSearchParams();

  const {
    type,
    availableCatalogTypes,
    handleChangeType,
    viewGroups,
    viewProducts,
    isAvailabilitiesLoading,
    catalogsAvailabilityInfo,
    onSelectOrg,
    isAvailabilityHasChanges,
    isLoading,
    selectedGroupID,
    selectedProductIDs,
    toggleGroup,
    onToggleAllProductsSelected,
    onToggleProduct,
    isAvailabilitiesSaving,
    onSaveAvailability,
    onCancelClick,
    isOrgHaveAvailability,
    selectedSubtypeID,
    setSelectedSubtypeID,
    subtypeItems,
  } = useAvailabilities();

  const selectedOrgId = catalogsAvailabilityInfo[catalogId];
  const areControlButtonsShown =
    !isAvailabilitiesLoading && (isAvailabilityHasChanges || isOrgHaveAvailability === false);

  const subtypeFilterValue = selectedSubtypeID ? subtypeItems.find((st) => st.id === selectedSubtypeID)?.name : '';
  const groupFilterValue = selectedGroupID ? viewGroups.find((g) => g.id === selectedGroupID)?.name : '';

  return (
    <Stack sx={{ height: '100%' }}>
      <TypeSubtypeGroupFilters
        type={type}
        catalogTypes={availableCatalogTypes}
        onTypeChange={handleChangeType}
        subtypeFilterValue={subtypeFilterValue}
        subtypeItems={subtypeItems}
        onChangeSubtype={setSelectedSubtypeID}
        groupFilterValue={groupFilterValue}
        groupItems={viewGroups}
        onChangeGroup={toggleGroup}
      />

      <Stack
        direction="row"
        spacing={5}
        sx={{
          position: 'relative',
          flexGrow: 1,
          pb: 2.5,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          ...scrollStyles,
        }}
      >
        <CatalogAvailabilityOrgsTable
          isLoading={isAvailabilitiesLoading}
          currentOrgId={selectedOrgId}
          onSelectOrg={onSelectOrg}
        />

        {selectedOrgId ? (
          <>
            {/* <TypesTable type={type} catalogTypes={availableCatalogTypes} onChangeType={handleChangeType} />

            <GroupsTable
              groups={viewGroups}
              selectedGroupID={selectedGroupID}
              selectedProductIDs={selectedProductIDs}
              toggleGroup={toggleGroup}
              isLoading={isLoading}
              subtypeFilterValue={subtypeFilterValue}
              subtypeItems={subtypeItems}
              onChangeSubtype={setSelectedSubtypeID}
            /> */}

            <CatalogAvailabilityProducts
              isLoading={isLoading}
              viewProducts={viewProducts}
              selectedProductIDs={selectedProductIDs}
              selectedGroupName={groupFilterValue}
              onToggleAll={onToggleAllProductsSelected}
              onToggleProduct={onToggleProduct}
            />

            {areControlButtonsShown && (
              <Stack
                direction="row"
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  right: 20,
                  justifyContent: 'flex-end',
                  py: 2.5,
                  width: 'calc(100% - 225px - 40px)',
                  borderTop: '1px solid #D4D4D4',
                }}
              >
                <SaveControl
                  onSave={onSaveAvailability}
                  onCancel={onCancelClick}
                  btnTexts={['Save Changes']}
                  isLoading={isAvailabilitiesSaving}
                />
              </Stack>
            )}
          </>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', height: 120, bgcolor: '#F2F4F7', borderTop: '1px solid #D4D4D4' }}
          >
            <Typography sx={{ fontSize: 18, fontWeight: 600, letterSpacing: '0.18px', color: '#242731' }}>
              {t('Please select an Organization to view product availability')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
