import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { TableSearch } from 'features/TableSearch/TableSearch';
import { FC, startTransition } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useCatalogUserPermissions } from 'shared/hooks';
import { CellType, TableFilter } from 'shared/models';
import { startEditing } from 'shared/slices';
import { HoverToolTip, Icon } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { CatalogFiltersPanel } from 'features/CatalogFiltersPanel/CatalogFiltersPanel';

type Props = {
  isEditing: boolean;
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  filters: TableFilter[];
  onChangeFilters: (filters: TableFilter[]) => void;
};

export const TableHeader: FC<Props> = ({ isEditing, tableSearch, filters, onChangeFilters }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { id: catalogId } = useParams();

  const { isCanEditCatalog } = useCatalogUserPermissions();

  const isEditButtonShown = isCanEditCatalog && !isEditing;

  const handleStartEditing = () => {
    startTransition(() => {
      catalogId && dispatch(startEditing(catalogId));
    });
  };

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{ position: 'relative', py: 1.5, px: 4, borderBlock: '1px solid #D4D4D4', bgcolor: '#ECECEC' }}
      >
        <TableSearch tableSearch={tableSearch} />

        <CatalogFiltersPanel filters={filters} onChangeFilters={onChangeFilters} />

        {isEditButtonShown && (
          <HoverToolTip
            title={'You cant edit this catalog'}
            disableHoverListener={isCanEditCatalog}
            disableFocusListener={isCanEditCatalog}
            disableTouchListener={isCanEditCatalog}
            placement="top-start"
          >
            <ButtonBase
              onClick={handleStartEditing}
              sx={{
                py: 1,
                px: 2,
                gap: 1,
                height: 37,
                borderRadius: '6px',
                border: '1px solid #DDE0E6',
                bgcolor: '#242731',
              }}
            >
              <Typography sx={{ fontWeight: 500, lineHeight: '130%', color: '#FFF' }}>
                {t('Edit Table')}
              </Typography>

              <Icon path={'products-view/edit'} />
            </ButtonBase>
          </HoverToolTip>
        )}
      </Stack>

      {isEditing && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(52, 64, 84, 0.50)',
            zIndex: 1,
          }}
        />
      )}
    </Stack>
  );
};
