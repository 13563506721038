import { FC, useRef } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Organization } from 'shared/models';
import { Icon } from 'shared/ui';
import { borderDashStyle } from '../OrganizationItem/borderDashStyle';

interface Props {
  org: Organization;
  depth: number;
  isOpen?: boolean;
  onToggle?: () => void;
  currentOrgId: string;
  onSelectOrg: (id: string) => void;
  disabled: boolean;
}

export const OrgTitle: FC<Props> = ({ org, depth, isOpen, onToggle, currentOrgId, onSelectOrg, disabled }) => {
  const refTitle = useRef<HTMLDivElement>(null);

  const haveSubOrgs = org.subOrganizations.length > 0;
  const isSelected = org.organizationId === currentOrgId;

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!onToggle || !haveSubOrgs) return;

    onToggle();
  };

  return (
    <Stack
      onClick={() => onSelectOrg(org.organizationId)}
      ref={refTitle}
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        pl: `${depth * 36 + 4}px`,
        height: 38,
        bgcolor: isSelected ? 'rgba(29, 135, 69, 0.08)' : 'transparent',
        cursor: 'pointer',
        '&:not(:last-child)': { borderBottom: '0.5px solid #D4D4D4' },
      }}
    >
      <Stack justifyContent="center" sx={{ position: 'relative' }}>
        {!!depth && (
          <Box
            sx={{
              position: 'absolute',
              width: '29px',
              height: '2px',
              right: '20px',
              top: '50%',
              transform: 'translateY(-1px)',
              ...borderDashStyle,
            }}
          />
        )}

        {haveSubOrgs ? (
          <IconButton
            onClick={handleOpen}
            onDoubleClick={handleOpen}
            sx={{
              width: '32px',
              height: '32px',
              transition: 'all 0.3s',
              ...(!isOpen && { transform: 'rotate(-90deg)' }),
            }}
          >
            <Icon path={`org-arrow`} />
          </IconButton>
        ) : (
          <Icon path={'org-sub-ball'} sx={{ px: 1 }} />
        )}
      </Stack>

      <Typography
        noWrap
        sx={{ color: disabled ? 'info.dark' : '#242731', textTransform: 'capitalize', userSelect: 'none' }}
      >
        {org.organizationName}
      </Typography>
    </Stack>
  );
};
