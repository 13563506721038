import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { axiosBase } from 'shared/axios';
import { TableProductInfo } from 'shared/models';
import { useAppDispatch, useCatalogSearchParams } from 'shared/hooks';
import { setShouldUpdateCatalogProducts } from 'shared/slices';
import { validateTranslationValue } from 'shared/lib';

type Args = {
  productItem: TableProductInfo;
  selectedLanguageId: string;
  descriptions: string[];
  translations: string[];
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

type TranslationValues = {
  description?: string;
  translation?: string;
};

export const useProductTranslations = ({
  productItem,
  descriptions,
  translations,
  selectedLanguageId,
  updateProduct,
  onKeyDown,
}: Args) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  // current product translation values
  // use ref to avoid unnecessary component rerender
  const savedProductValuesRef = useRef<TranslationValues | null>(null);
  const [localProductItem, setLocalProductItem] = useState<TableProductInfo>(productItem);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [translationError, setTranslationError] = useState<string | null>(null);

  useEffect(() => {
    setLocalProductItem(productItem);
  }, [productItem]);

  useEffect(() => {
    const currentTranslation = productItem.stringResources?.find(
      (translation) => translation.languageId === selectedLanguageId
    )?.value;

    savedProductValuesRef.current = { description: productItem.description, translation: currentTranslation };
  }, [selectedLanguageId, productItem]);

  const onDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newDesc = e.target.value;

      setLocalProductItem((prev) => ({ ...prev, description: newDesc }));

      const savedDescription = savedProductValuesRef.current?.description;
      const validatedDescr = validateTranslationValue(newDesc, savedDescription, descriptions, 'Description');
      setDescriptionError(validatedDescr);
    },
    [savedProductValuesRef.current?.description, descriptions]
  );

  const onTranslationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newTranslation = e.target.value;

      const stringResources = localProductItem.stringResources;

      const newTranslationData = {
        languageId: selectedLanguageId,
        value: newTranslation,
      };

      const savedTranslation = savedProductValuesRef.current?.translation;
      const validatedTranslation = validateTranslationValue(
        newTranslation,
        savedTranslation,
        translations,
        'Translation'
      );
      setTranslationError(validatedTranslation);

      if (stringResources) {
        const filteredStringResources = stringResources.filter((sr) => sr.languageId !== selectedLanguageId);

        const newStringResources = [...filteredStringResources, newTranslationData];

        setLocalProductItem((prev) => ({
          ...prev,
          stringResources: newStringResources,
        }));

        return;
      }

      setLocalProductItem((prev) => ({
        ...prev,
        stringResources: [newTranslationData],
      }));
    },
    [savedProductValuesRef.current?.translation, translations]
  );

  const getDescriptionChanges = () => {
    const localDescription = localProductItem.description;
    const savedDescription = savedProductValuesRef.current?.description;

    if (localDescription === savedDescription) return;
    return localDescription;
  };

  const getTranslationChanges = () => {
    const localTranslation = localProductItem.stringResources?.find((sr) => sr.languageId === selectedLanguageId)
      ?.value;
    const savedTranslation = savedProductValuesRef.current?.translation;

    if (localTranslation === savedTranslation) return;
    return localTranslation;
  };

  const onBlur = async () => {
    if (descriptionError || translationError) return;

    const changedDescription = getDescriptionChanges();
    const changedTranslation = getTranslationChanges();

    if (!changedDescription && !changedTranslation) return;

    try {
      const body = [
        {
          itemRef: 0,
          operation: 'Update',
          groupId: localProductItem.groupId,
          componentId: localProductItem.id,
          componentData: {
            ...productItem,
            description: changedDescription,
            descriptionStringResources: localProductItem.stringResources,
          },
        },
      ];

      await axiosBase.put(`/Catalogs/${catalogId}/components`, body);

      dispatch(setShouldUpdateCatalogProducts(true));
      updateProduct(localProductItem);

      if (!savedProductValuesRef.current) return;

      // update saved values
      if (changedDescription) {
        savedProductValuesRef.current.description = changedDescription;
        return;
      }

      savedProductValuesRef.current.translation = changedTranslation;
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onBlur();
    } else {
      onKeyDown(e);
    }
  };

  return {
    localProductItem,
    descriptionError,
    translationError,
    onDescriptionChange,
    onTranslationChange,
    onBlur,
    handleKeyDown,
  };
};
