// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Autocomplete, createFilterOptions, Stack, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useCatalogsControl } from 'shared/hooks';
import { CellType, TableProductInfo } from 'shared/models';
import { useGridApiContext, useGridApiEventHandler, GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { changeProductItem, changeProductItemByArrayCell, removeCellError } from 'shared/slices';
import { PRODUCT_KEY } from 'shared/constants';

const fatherComponentFields = [
  PRODUCT_KEY.SKU,
  PRODUCT_KEY.DESC,
  PRODUCT_KEY.BRAND,
  PRODUCT_KEY.SUBTYPE,
  PRODUCT_KEY.GROUP,
  PRODUCT_KEY.GROUP_ID,
  PRODUCT_KEY.NOZZLE,
  PRODUCT_KEY.SWIVEL,
];

const staticDcFields = [
  PRODUCT_KEY.SKU,
  PRODUCT_KEY.DESC,
  PRODUCT_KEY.BRAND,
  PRODUCT_KEY.SUBTYPE,
  PRODUCT_KEY.NOZZLE,
  PRODUCT_KEY.SWIVEL,
];

export const RenderDCEditModeCell = (params: GridRenderEditCellParams & { maxWidthDesc: number }) => {
  return <CellContainer {...params} maxWidthDesc={params.maxWidthDesc} />;
};

const CellContainer = (params: GridRenderEditCellParams & { maxWidthDesc: number }) => {
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();

  const { field, value: paramsValue } = params;

  const { catalogId, items, cellErrors, emitters, catalogTypeGroups } = useCatalogsControl();

  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(!open);

  const id = params.id.toString();

  const getFieldConfig = () => {
    let options;
    let optionLabel;

    const item = items?.find((c) => c.id === id);

    switch (field) {
      case PRODUCT_KEY.DESC:
        options = [...emitters];

        if (item?.subtype) {
          options = options.filter((d) => d.subtype === item?.subtype);
        }
        if (item?.group) {
          options = options.filter((d) => d.group === item?.group);
        }

        optionLabel = 'description';
        break;
      case PRODUCT_KEY.GROUP: {
        const uniqueGroups = [...new Set(emitters.map((item) => item.group))];
        options = catalogTypeGroups.filter((g) => uniqueGroups.includes(g.name));

        optionLabel = 'name';
        break;
      }

      default:
        options = [];
        optionLabel = '';
    }

    return { options, optionLabel };
  };

  const { options, optionLabel } = getFieldConfig();

  const [currentComponent, setCurrentComponent] = useState<any>(null);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const onChangeComponent = () => setSelectedComponent(currentComponent);

  const autocompleteRef = useRef<HTMLDivElement>(null);

  const [menuPosition, setMenuPosition] = useState(0);
  const [isMenuBelowCell, setIsMenuBelowCell] = useState<boolean | null>(null);

  const MENU_ITEM_HEIGHT = 36;
  const PADDING_HEIGHT = options.length >= 5 ? 8 : 16;
  const VISIBLE_ITEMS_COUNT = options.length >= 5 ? 5 : options.length;

  const minRequiredMenuHeight = PADDING_HEIGHT + MENU_ITEM_HEIGHT * VISIBLE_ITEMS_COUNT;

  useEffect(() => {
    if (autocompleteRef.current) {
      const rect = autocompleteRef.current.getBoundingClientRect();
      const cellTop = rect.top;
      const cellBottom = cellTop + 37;
      const pageHeight = window.innerHeight;

      if (pageHeight - cellBottom < minRequiredMenuHeight) {
        setIsMenuBelowCell(false);
      } else {
        setMenuPosition(cellBottom);
        setIsMenuBelowCell(true);
      }
    }
  }, [autocompleteRef.current]);

  const handleResetDCValues = (id: string) => {
    staticDcFields.forEach((field) => {
      const updatedCell: CellType = { id, field, value: '' };
      catalogId && dispatch(changeProductItem({ catalogId, cell: updatedCell }));
    });
  };

  const updateGroupValue = (selectedValue: any) => {
    const value = selectedValue.name;

    if (value === paramsValue) return;

    const nameCell: CellType = { id, field, value };
    const cells = [nameCell];

    const group = options.find((g) => g.name === value);
    const subtypeCell: CellType = {
      id,
      field: PRODUCT_KEY.SUBTYPE,
      value: group?.subtype?.name ?? '',
    };

    handleResetDCValues(id);
    cells.push(subtypeCell);

    catalogId && dispatch(changeProductItemByArrayCell({ catalogId, cells }));
  };

  const updateDescription = (selectedValue: TableProductInfo) => {
    fatherComponentFields.forEach((field) => {
      const updatedCell: CellType = { id, field, value: selectedValue[field] };
      catalogId && dispatch(changeProductItem({ catalogId, cell: updatedCell }));
      cellErrors[id]?.[field] && dispatch(removeCellError({ catalogId, cell: updatedCell }));
    });

    const fatherIdCell: CellType = { id, field: PRODUCT_KEY.FATHER_ID, value: selectedValue.id };
    catalogId && dispatch(changeProductItem({ catalogId, cell: fatherIdCell }));
  };

  const handleSaveChanges = () => {
    if (!selectedComponent && !currentComponent) return;

    const selectedValue = selectedComponent ?? currentComponent;

    if (field === PRODUCT_KEY.GROUP) {
      updateGroupValue(selectedValue);
    } else if (field === PRODUCT_KEY.DESC) {
      updateDescription(selectedValue);
    }
  };

  useGridApiEventHandler(apiRef, 'cellEditStop', handleSaveChanges);

  return (
    <Stack justifyContent="center" sx={{ width: '100%', height: '100%', bgcolor: 'info.main' }}>
      <Autocomplete
        ref={autocompleteRef}
        open={open}
        onOpen={toggle}
        onClose={toggle}
        onChange={onChangeComponent}
        onHighlightChange={(e, c) => setCurrentComponent(c)}
        value={selectedComponent}
        options={options ?? []}
        getOptionLabel={(item) => item[optionLabel] || ''}
        isOptionEqualToValue={(option, value) => option.description === value.description}
        filterOptions={createFilterOptions({ matchFrom: 'any' })}
        renderInput={(params) => <TextField {...params} autoFocus />}
        componentsProps={{
          paper: {
            sx: {
              position: 'absolute',
              top: isMenuBelowCell ? '-2px' : `-${minRequiredMenuHeight + 37}px`,
            },
          },
        }}
        slotProps={{
          popper: {
            onMouseLeave: () => setCurrentComponent(null),
            sx: {
              '& .MuiAutocomplete-listbox': {
                ...(isMenuBelowCell === null && { display: 'none' }),
                ...(field === PRODUCT_KEY.DESC && { width: 'max-content' }),
                maxHeight: isMenuBelowCell ? `calc(100vh - ${menuPosition}px)` : minRequiredMenuHeight,
              },
            },
          },
        }}
        sx={{
          flexGrow: 1,
          '.MuiInputBase-input': {
            pl: '8px !important',
          },
          '.MuiOutlinedInput-root': {
            // mx: 0,
            p: '0 !important',

            '.MuiAutocomplete-popupIndicator': { display: 'none' },
            '.MuiAutocomplete-endAdornment': { display: 'none' },
            '& > fieldset': { border: 'none !important' },
          },
        }}
      />
    </Stack>
  );
};
