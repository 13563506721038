import { ChangeEvent, FC } from 'react';
import { Stack, SxProps, TextField } from '@mui/material';
import { RtlProvider } from 'app/providers/RtlProvider';
import { ErrorMessagePopover } from './ErrorMessagePopover';
import { SearchData, SearchType } from 'shared/hooks';
import { HighlightedText } from 'shared/ui';

type Props = {
  value?: string;
  isSelected: boolean;
  error: string | null;
  onUpdateItem: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  searchData: SearchData;
  searchType: SearchType;
  withRTLPropvider?: boolean;
  isHebrew?: boolean;
  sx?: SxProps;
};

export const TranslationItem: FC<Props> = ({
  value,
  error,
  isSelected,
  searchData,
  searchType,
  isHebrew = false,
  withRTLPropvider,
  onUpdateItem,
  sx,
}) => {
  const translationItemContent = (
    <>
      {isSelected ? (
        <Stack direction="row" sx={{ width: '100%' }}>
          <TextField
            value={value}
            fullWidth
            onChange={onUpdateItem}
            onKeyDown={(e) => {
              if (e.key === 'Enter') (e.target as HTMLInputElement).blur();
            }}
            sx={{
              input: {
                p: 0,
                height: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                ...(isHebrew && { fontFamily: 'Helvetica' }),
              },
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
          />

          {!!error && <ErrorMessagePopover error={error} />}
        </Stack>
      ) : (
        <HighlightedText
          noWrap
          value={value}
          searchData={searchData}
          searchType={searchType}
          sx={{
            lineHeight: '20px',
            ...(isHebrew && { fontFamily: 'Helvetica' }),
          }}
        />
      )}
    </>
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        padding: '8px 20px 8px 12px',
        width: '50%',
        height: '100%',
        borderRadius: 0,
        color: '#242731',
        outline: 'none',
        '&:focus': { outline: 'none' },
        ...(error && { borderWidth: 2, borderColor: 'red', border: '2px solid red !important' }),
        ...sx,
      }}
    >
      {withRTLPropvider ? (
        <RtlProvider enable={isHebrew}>{translationItemContent}</RtlProvider>
      ) : (
        translationItemContent
      )}
    </Stack>
  );
};
