import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import {
  GridCellCoordinates,
  ElementSize,
  GridRowId,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridEventListener,
  MuiEvent,
  GridColType,
} from '@mui/x-data-grid-pro';
import { useState, useRef, useEffect, MutableRefObject, useCallback } from 'react';
import {
  PRODUCT_KEY,
  CATALOG_SPEC,
  GENERAL_COLUMNS,
  PIPE_COLUMNS,
  EMITTER_COLUMNS,
  INTEGRAL_COLUMNS,
  DISTRIBUTION_CURVE_COLUMNS,
} from 'shared/constants';
import {
  getCurrentViewCells,
  copyCellsToClipboard,
  getCellsFromClipboard,
  checkIsCurveCellDisabled,
} from 'shared/lib';
import { TableProductInfo, CellType, Group } from 'shared/models';
import { localStorageService } from 'shared/services';
import {
  clearCellErrors,
  selectCells,
  pasteCopiedCells,
  undoProductAction,
  removeCellError,
  changeProductItemByArrayCell,
} from 'shared/slices';
import { ExtendedGridColDef } from '../GridContainer';
import { useAppDispatch } from 'shared/hooks';

type Args = {
  apiRef: MutableRefObject<GridApiPremium>;
  catalogId: string | undefined;
  isEditing: boolean;
  isValidating: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  onToggleValidating: () => void;
  columns: ExtendedGridColDef[];
  specification: number;
  items: TableProductInfo[];
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  isDistributionCurveType: boolean;
  catalogTypeGroups: Group[];
  ROW_HEIGHT: number;
};

export const useDataGrid = ({
  apiRef,
  catalogId,
  isEditing,
  isValidating,
  cellErrors,
  onToggleValidating,
  items,
  specification,
  newItemIDs,
  isDistributionCurveType,
  tableSearch,
  changedItems,
  columns,
  catalogTypeGroups,
  deletedItemIDs,
  ROW_HEIGHT,
}: Args) => {
  const dispatch = useAppDispatch();

  const [isSelectedFullRow, setIsSelectedFullRow] = useState(false);

  const editCellCoord = useRef<GridCellCoordinates | null>(null);

  const [menuPosition, setMenuPosition] = useState(0);
  const [menuHeight, setMenuHeight] = useState<string | number>('auto');

  const isEditingRef = useRef(isEditing);

  // const distributionCurveTopComponentIDs = isDistributionCurveType ? getCurveTopComponentIDs(items) : [];

  useEffect(() => {
    isEditingRef.current = isEditing;
  }, [isEditing]);

  useEffect(() => {
    if (!isDistributionCurveType || !isEditing) return;

    // update grid rows when cell value was changed in DC table
    apiRef.current.updateRows(items);
  }, [items]);

  useEffect(() => {
    if (isValidating) {
      const errorIDs = Object.keys(cellErrors);
      if (!errorIDs.length) return;

      const firstErrorID = errorIDs[0];
      const firstErrorField = Object.keys(cellErrors[firstErrorID])[0];

      const colIndex = apiRef.current.getColumnIndex(firstErrorField);
      const rowIndex = apiRef.current.getAllRowIds().findIndex((id) => id === firstErrorID);

      const dimensions = apiRef.current.getRootDimensions()?.viewportInnerSize as ElementSize;

      const top = ROW_HEIGHT * (rowIndex - 1) - dimensions?.height / 2;
      apiRef.current.scrollToIndexes({ rowIndex, colIndex });
      apiRef.current.scroll({ top });

      const errorCell = { id: firstErrorID, field: firstErrorField, value: '' };

      handleOpenCellEditMode(errorCell);
      onToggleValidating();
    }
  }, [isValidating]);

  useEffect(() => {
    if (isEditing) {
      const width = apiRef.current.getColumn(PRODUCT_KEY.DESC)?.width;
      if (!width) return;

      const widths = localStorageService.catalogsColumnWidths;
      const currentTableWidths = widths?.[catalogId as string] ?? {};

      currentTableWidths[PRODUCT_KEY.DESC] = width;
      localStorageService.catalogsColumnWidths = { ...widths, [catalogId as string]: currentTableWidths };
    }
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      const errors = Object.entries(cellErrors ?? {});
      if (!errors.length) return;

      errors.forEach(([id, fields]) => {
        for (const field in fields) {
          const mode = apiRef.current.getCellMode(id as GridRowId, field);
          const cellType = apiRef.current.getColumn(field).type;

          if (mode === 'edit') {
            const product = items.find((item) => item.id === id);

            let value = product ? product[field as keyof TableProductInfo] : '';
            value = cellType == 'number' && !value ? '' : value;
            apiRef.current.setEditCellValue({ id: id as GridRowId, field, value });
            apiRef.current.stopCellEditMode({ id: id as GridRowId, field });
          }
        }
      });

      catalogId && dispatch(clearCellErrors(catalogId));
    }
  }, [isEditing]);

  useEffect(() => {
    const FIRST_SECTION_COL_IDX = 0;
    const FIRST_UNPINNED_COL_IDX = 3;
    const PLACE_ONE_COL_IDX = 6;
    const PLACE_26_COL_IDX = 32;

    let field = '';
    if (specification === CATALOG_SPEC.GENERAL) {
      field = GENERAL_COLUMNS[FIRST_UNPINNED_COL_IDX].field;
    }
    if (specification === CATALOG_SPEC.PIPE) {
      field = PIPE_COLUMNS[FIRST_SECTION_COL_IDX].field;
    }
    if (specification === CATALOG_SPEC.EMITTER) {
      field = EMITTER_COLUMNS[FIRST_SECTION_COL_IDX].field;
    }
    if (specification === CATALOG_SPEC.INTEGRAL) {
      field = INTEGRAL_COLUMNS[FIRST_SECTION_COL_IDX].field;
    }
    if (specification === CATALOG_SPEC.PLACE_0_26) {
      field = DISTRIBUTION_CURVE_COLUMNS[PLACE_ONE_COL_IDX].field;
    }
    if (specification === CATALOG_SPEC.PLACE_26_52) {
      field = DISTRIBUTION_CURVE_COLUMNS[PLACE_26_COL_IDX].field;
    }

    const columns = apiRef.current.getAllColumns();
    const colIndex = columns.findIndex((c) => c.field === field);
    const left = columns
      .map((c) => c.width as number)
      .slice(3, colIndex)
      .reduce((sum, num) => sum + num, 0);

    setTimeout(() => apiRef.current?.scroll({ left }), 0);
  }, [specification]);

  useEffect(() => {
    if (!newItemIDs) return;
    const lastId = Object.keys(newItemIDs).at(-1);
    if (!isDistributionCurveType && lastId) {
      apiRef.current.startCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.SKU });
    }
    if (isDistributionCurveType && lastId) {
      // this will cause scroll to a new row but will not open a drop-down
      apiRef.current.startCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.DESC });

      setTimeout(() => {
        const mode = apiRef.current.getCellMode(lastId as GridRowId, PRODUCT_KEY.DESC);
        if (mode === 'edit') {
          try {
            apiRef.current.stopCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.DESC });
          } catch (error) {
            console.log(error);
          }
        }
      }, 0);
    }
  }, [newItemIDs]);

  useEffect(() => {
    if (tableSearch?.cells?.length) {
      const cell = tableSearch.cells[tableSearch.currentIdx];
      const rowIndex = apiRef.current.getAllRowIds().findIndex((id) => id === cell.id);
      const colIndex = apiRef.current.getColumnIndex(cell.field);

      apiRef.current.scrollToIndexes({ colIndex, rowIndex });
    }
  }, [tableSearch]);

  const isCellFocused = useRef<boolean>(false);

  useEffect(() => {
    window.addEventListener('keydown', handleTabKeyDown);
    window.addEventListener('keydown', handleInputMinus);
    window.addEventListener('keydown', handleHotKeyDown);

    apiRef.current.subscribeEvent('cellEditStart', () => {
      isCellFocused.current = true;
    });

    apiRef.current.subscribeEvent('cellEditStop', () => {
      isCellFocused.current = false;
    });

    return () => {
      window.removeEventListener('keydown', handleTabKeyDown);
      window.removeEventListener('keydown', handleHotKeyDown);
      window.removeEventListener('keydown', handleInputMinus);
    };
  }, []);

  const handleSaveSelectedCells = () => {
    const selectedRowIDs = Array.from(apiRef.current.getSelectedRows().keys());

    let cellCoords: GridCellCoordinates[];

    if (selectedRowIDs.length) {
      const columns = apiRef.current
        .getAllColumns()
        .map((c) => c.field)
        .filter((c) => c !== GRID_CHECKBOX_SELECTION_FIELD);

      const coords: GridCellCoordinates[] = [];
      for (let i = 0; i < selectedRowIDs.length; i++) {
        const id = selectedRowIDs[i];
        for (let y = 0; y < columns.length; y++) {
          const field = columns[y];
          const coord: GridCellCoordinates = { id, field };
          coords.push(coord);
        }
      }
      cellCoords = coords;
    } else {
      cellCoords = apiRef.current.unstable_getSelectedCellsAsArray();
    }

    const cells = cellCoords.map(({ id, field }) => {
      const value = apiRef.current.getCellValue(id, field);

      const cell: CellType = { id: id.toString(), field, value };

      return cell;
    });
    const currentCells = getCurrentViewCells(cells, items, changedItems);

    if (currentCells.length) {
      dispatch(selectCells(currentCells));
    }

    return currentCells;
  };

  const handleOpenCellEditMode = (c: CellType) => {
    const params = apiRef.current.getCellParams(c.id as GridRowId, c.field);
    const isEditable = params.isEditable;
    const isViewMode = params.cellMode === 'view';

    if (isEditing && isEditable && isViewMode) {
      apiRef.current.startCellEditMode({ id: c.id as GridRowId, field: c.field });
      const cellElement = apiRef.current.getCellElement(c.id as GridRowId, c.field);
      cellElement && calculateMenuPosition(cellElement, c.field);
    }
  };

  const handleHorizontalScroll = (side: 'right' | 'left') => {
    let left = apiRef.current.getScrollPosition().left;
    if (side === 'left') {
      left -= 50;
    }
    if (side === 'right') {
      left += 50;
    }
    apiRef.current.scroll({ left });
  };

  const handleTabKeyDown = (e: KeyboardEvent) => {
    if (e.code == 'Tab') {
      e.preventDefault();
      const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
      let targetCell: GridCellCoordinates | null = null;

      if (selectedRowIDs[0]) {
        targetCell = { id: selectedRowIDs[0], field: GRID_CHECKBOX_SELECTION_FIELD };
      } else {
        const selectedCells = apiRef.current.unstable_getSelectedCellsAsArray();
        if (!selectedCells.length) return;

        const firstSelectedRowID = selectedCells[0].id;
        targetCell = selectedCells.filter((c) => c.id === firstSelectedRowID).at(-1) as GridCellCoordinates;
      }

      if (targetCell) {
        const columnFields = apiRef.current.getAllColumns().map((c) => c.field);
        const colIdx = columnFields.findIndex((f) => f === targetCell?.field);
        let nextIdx = colIdx + 1;
        nextIdx = nextIdx > columnFields.length - 1 ? 1 : nextIdx;

        const nextCellField = columnFields[nextIdx];

        const model = { [targetCell.id]: { [nextCellField]: true } };
        apiRef.current.unstable_setCellSelectionModel(model);
        apiRef.current.setCellFocus(targetCell.id, nextCellField);
        apiRef.current.scrollToIndexes({ colIndex: nextIdx });
      }
    }
  };

  const handleInputMinus = (e: KeyboardEvent) => {
    const forbiddenSymbols = ['Minus', 'KeyE'];

    if (forbiddenSymbols.includes(e.code)) {
      const isCellEditing =
        apiRef.current.getCellMode(
          editCellCoord.current?.id as GridRowId,
          editCellCoord.current?.field as string
        ) === 'edit';
      const isNumberType = apiRef.current.getColumn(editCellCoord.current?.field as string)?.type === 'number';

      if (isCellEditing && isNumberType) {
        e.preventDefault();
      }
    }
  };

  const handleHotKeyDown = async (e: KeyboardEvent) => {
    if (!catalogId || !isEditingRef.current) return;

    const activeElement = document.activeElement;
    // do not handle table key down if search input is focused
    if (activeElement?.id === 'table-search-input') return;

    if (e.code == 'KeyC' && (e.ctrlKey || e.metaKey) && !isCellFocused.current) {
      const cells = handleSaveSelectedCells();
      await copyCellsToClipboard(cells);
    }

    if (e.code == 'KeyV' && (e.ctrlKey || e.metaKey) && !isCellFocused.current) {
      // trick for correct paste
      e.preventDefault();

      handleSaveSelectedCells();
      const cells = await getCellsFromClipboard();

      catalogId && dispatch(pasteCopiedCells({ catalogId, cells }));

      // trick for correct paste
      apiRef.current.setEditCellValue({
        id: editCellCoord.current?.id || '',
        field: editCellCoord.current?.field || '',
        value: cells[0].value?.toString(),
      });
    }

    if (e.code == 'KeyZ' && (e.ctrlKey || e.metaKey) && !isCellFocused.current) {
      e.preventDefault();
      catalogId && dispatch(undoProductAction(catalogId));
    }
  };

  const handleCellDoubleClick: GridEventListener<'cellDoubleClick'> = (_, event) => {
    const cell = event.currentTarget;
    if (!cell) return;

    // get parent element
    const cellContainer = cell.parentElement;
    if (!cellContainer) return;

    // get <p> tag with cell value text
    const textElement = cellContainer.querySelector('p');
    if (!textElement) return;

    // select cell value text
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(textElement);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const calculateMenuPosition = (cellElement: HTMLElement, field: string) => {
    const valueOptions = columns.find((column) => column.field === field)?.valueOptions;
    const options = Array.isArray(valueOptions) ? valueOptions : [];

    const MENU_ITEM_HEIGHT = 36;
    const PADDING_HEIGHT = options.length >= 5 ? 8 : 16;
    const VISIBLE_ITEMS_COUNT = options.length >= 5 ? 5 : options.length;
    const minRequiredMenuHeight = PADDING_HEIGHT + MENU_ITEM_HEIGHT * VISIBLE_ITEMS_COUNT;

    const rect = cellElement.getBoundingClientRect();
    const cellTop = rect.top;
    const cellBottom = cellTop + 32;
    const pageHeight = window.innerHeight;

    if (pageHeight - cellBottom < minRequiredMenuHeight) {
      setMenuPosition(cellTop - minRequiredMenuHeight - 4);
      setMenuHeight(minRequiredMenuHeight);
      return;
    }
    setMenuPosition(cellBottom);
    setMenuHeight(`calc(100vh - ${cellBottom}px)`);
  };

  const getCellClassName = (id: string, field: string) => {
    if (isDistributionCurveType && checkIsCurveCellDisabled(field)) {
      return isEditing
        ? field === PRODUCT_KEY.DESC || field === PRODUCT_KEY.GROUP || field === PRODUCT_KEY.SUBTYPE
          ? 'no_padding'
          : 'grey_cell'
        : '';
    }

    if (deletedItemIDs[id]) return '';

    const error = cellErrors?.[id]?.[field];
    if (error) return 'error_cell';

    return '';
  };

  const handleCellEditStart = (id: GridRowId, field: string) => {
    editCellCoord.current = { id, field };
    const model = { [id]: { [field]: true } };
    apiRef.current.unstable_setCellSelectionModel(model);
  };

  const handleCellEditStop = useCallback(
    (id: string, field: string, value: any, columnType?: GridColType) => {
      editCellCoord.current = null;

      if (columnType !== 'singleSelect') return;

      const newValue = (apiRef.current.unstable_getEditCellMeta(id, field) as { value: string }).value;
      if (newValue === value) return;

      const nameCell: CellType = { id, field, value: newValue };
      const cells = [nameCell];

      cellErrors[id]?.[field] && catalogId && dispatch(removeCellError({ catalogId, cell: nameCell }));

      if (field === PRODUCT_KEY.GROUP) {
        const group = catalogTypeGroups.find((g) => g.name === newValue);
        const subtypeCell: CellType = {
          id,
          field: PRODUCT_KEY.SUBTYPE,
          value: group?.subtype?.name ?? '',
        };

        cells.push(subtypeCell);
      }

      if (field === PRODUCT_KEY.SUBTYPE) {
        const descriptionCell: CellType = {
          id,
          field: PRODUCT_KEY.DESC,
          value: '',
        };
        const groupCell: CellType = {
          id,
          field: PRODUCT_KEY.GROUP,
          value: '',
        };

        cells.push(descriptionCell, groupCell);
      }

      catalogId && dispatch(changeProductItemByArrayCell({ catalogId, cells }));
    },
    [catalogId, cellErrors, catalogTypeGroups]
  );

  const checkIsCellEditable = (id: string, field: string) => {
    if (deletedItemIDs[id]) return false;
    const error = cellErrors?.[id]?.[field];
    if (error) return true;

    if (isDistributionCurveType && isEditing) {
      if (
        newItemIDs[id] &&
        (field === PRODUCT_KEY.DESC || field === PRODUCT_KEY.GROUP || field === PRODUCT_KEY.SUBTYPE)
      ) {
        return true;
      }

      return !checkIsCurveCellDisabled(field);
    }

    return isEditing;
  };

  const handleCellSelectionModelChange = useCallback(() => {
    setIsSelectedFullRow(false);

    const rowIds = apiRef.current.getAllRowIds();
    apiRef.current.selectRows(rowIds, false, false);

    const arrowCells = apiRef.current
      .unstable_getSelectedCellsAsArray()
      .filter((c) => c.field === GRID_CHECKBOX_SELECTION_FIELD);

    if (!arrowCells.length) return;

    const cellIds = arrowCells.map((c) => c.id);
    apiRef.current.selectRows(cellIds, true, false);
    setIsSelectedFullRow(true);
  }, [apiRef.current]);

  const handleRowSelectionModelChange = () => {
    const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
    setIsSelectedFullRow(Boolean(selectedRowIDs.length));
  };

  const handleCellClick = useCallback(
    (e: MuiEvent<React.MouseEvent<HTMLElement>>, field: string, id: GridRowId, columnType?: GridColType) => {
      const isCtrlPressed = e.ctrlKey;
      const isShiftPressed = e.shiftKey;

      if (columnType === 'singleSelect') {
        const cellElement = e.currentTarget;
        calculateMenuPosition(cellElement, field);
      }

      if (field === GRID_CHECKBOX_SELECTION_FIELD) {
        const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
        apiRef.current.unstable_setCellSelectionModel({});

        if (isCtrlPressed) {
          apiRef.current.selectRows([...selectedRowIDs, id], true, true);
          return;
        }

        if (isShiftPressed) {
          if (!selectedRowIDs.length) {
            apiRef.current.selectRows([id], false, true);
            return;
          }
          const topRowId = selectedRowIDs[0];
          const bottomRowId = selectedRowIDs[selectedRowIDs.length - 1];
          const rowIDs = apiRef.current.getAllRowIds();

          const topIdx = rowIDs.findIndex((rId) => rId === topRowId);
          const bottomIdx = rowIDs.findIndex((rId) => rId === bottomRowId);
          const selectedIdx = rowIDs.findIndex((rId) => rId === id);

          let firstIdx: number, lastIdx: number;

          if (selectedIdx <= topIdx) firstIdx = selectedIdx;
          if (selectedIdx >= bottomIdx) lastIdx = selectedIdx;
          if (selectedIdx > topIdx && selectedIdx < bottomIdx) {
            (lastIdx = selectedIdx), (firstIdx = topIdx);
          }

          const needToSelectIDs = rowIDs.filter((_, i) => i >= firstIdx && i <= lastIdx);
          apiRef.current.selectRows(needToSelectIDs, true, false);

          return;
        }

        apiRef.current.selectRows([id], false, true);
      }
    },
    [apiRef.current]
  );

  const handleColumnWidthChange = useCallback(
    (field: string, width: number) => {
      const columnWidth = +width.toFixed(0);
      const widths = localStorageService.catalogsColumnWidths;

      let currentTableWidths = widths?.[catalogId as string];

      if (currentTableWidths) {
        currentTableWidths[field] = columnWidth;
      } else {
        currentTableWidths = { [field]: columnWidth };
      }
      localStorageService.catalogsColumnWidths = {
        ...widths,
        [catalogId as string]: currentTableWidths,
      };
    },
    [catalogId, localStorageService.catalogsColumnWidths]
  );

  return {
    isSelectedFullRow,
    handleOpenCellEditMode,
    handleCellEditStart,
    handleCellEditStop,
    checkIsCellEditable,
    handleCellSelectionModelChange,
    handleRowSelectionModelChange,
    handleCellClick,
    handleColumnWidthChange,
    handleCellDoubleClick,
    handleHorizontalScroll,
    getCellClassName,
    handleSaveSelectedCells,
    menuHeight,
    menuPosition,
  };
};
