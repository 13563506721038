import { useState, useMemo } from 'react';
import { TableProductInfo } from 'shared/models';
import { productsFilterOptions } from '..';

export const useCatalogAvailabilityProducts = (products: TableProductInfo[], selectedProductIDs: string[]) => {
  const [filterValue, setFilterValue] = useState<keyof typeof productsFilterOptions>('all');

  const filteredProducts = useMemo(() => {
    switch (filterValue) {
      case 'available':
        return products.filter((p) => selectedProductIDs.includes(p.id));
      case 'not_available':
        return products.filter((p) => !selectedProductIDs.includes(p.id));
      default:
        return products;
    }
  }, [filterValue, products]);

  const searchableItems = useMemo(() => {
    return filteredProducts.map((p) => p.description ?? '');
  }, [filteredProducts]);

  const selectedProductItemsAmount = filteredProducts.filter((p) => selectedProductIDs.includes(p.id)).length;
  const areAllProdcutsSelected = selectedProductItemsAmount === filteredProducts.length && !!products.length;

  return {
    filterValue,
    searchableItems,
    setFilterValue,
    filteredProducts,
    selectedProductItemsAmount,
    areAllProdcutsSelected,
  };
};
