import { PRODUCT_KEY } from 'shared/constants';
import { CatalogItemState, CellType, TabItem, TableProductInfo } from 'shared/models';
import { localStorageService } from 'shared/services';
import { getNewEmptyProduct } from './factories';
import { NUMERIC_KEYS } from './tableCellValidation';
import { extractNumberFromString } from '../general';

export const unhandledKeys: string[] = [
  PRODUCT_KEY.ID,
  PRODUCT_KEY.GROUP_ID,
  PRODUCT_KEY.BRAND_ID,
  PRODUCT_KEY.FATHER_ID,
  PRODUCT_KEY.EDC_PROFILES,
  PRODUCT_KEY.STRING_RESOURCES,
];

export const getCellsFromClipboard = async () => {
  const data = await navigator.clipboard.readText();

  const rows = data.trimEnd().split('\n');
  const parsedData = rows.map((row) => row.split('\t'));

  const cells: CellType[] = [];

  for (let i = 0; i < parsedData.length; i++) {
    const row = parsedData[i];

    for (let j = 0; j < row.length; j++) {
      const value = row[j].replace(/[\n\t\r\v]/g, '');

      const cell: CellType = { id: `${i}`, field: `${j}`, value };
      cells.push(cell);
    }
  }

  return cells;
};

export const copyCellsToClipboard = async (cells: CellType[]) => {
  const cellIDs = [...new Set(cells.map((c) => c.id))];

  const resultStrings = cellIDs
    .map((cId) =>
      cells
        .filter((c) => c.id === cId)
        .map((c) => c.value?.toString() ?? '')
        .join('\t')
    )
    .join('\r\n');

  await navigator.clipboard.writeText(resultStrings);
};

export const getMaxDescriptionCellWidth = (items: TableProductInfo[]) => {
  const minCellWidth = 130;
  if (!items.length) return minCellWidth;

  const descriptions: string[] = items.map((c) => c.description as string);
  let maxLength = 0;

  descriptions.forEach((d) => {
    if ((d ?? '').length > maxLength) maxLength = d.length;
  });

  const padding = 20;
  const letterLength = 7.5;

  const cellWidth = padding + letterLength * maxLength;

  return cellWidth < minCellWidth ? minCellWidth : cellWidth;
};
export const getRegexpMatching = (search: string, value: string) => {
  const formattedSearchQuery = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pattern = new RegExp(formattedSearchQuery, 'i');
  value = value?.toString();

  return pattern.exec(value);
};

export const getDividedByRegexpText = (search: string, value: string) => {
  const match = getRegexpMatching(search, value ?? '');

  if (match) {
    const beforeMatch = value?.toString().substring(0, match.index);
    const firstMatch = match[0];
    const afterMatch = value?.toString().substring(match.index + match[0].length);

    return [beforeMatch, firstMatch, afterMatch];
  }
  return [];
};

export const getSearchedCells = (search: string, items: TableProductInfo[]) => {
  if (!search) return [];

  const searchableKeys: string[] = [PRODUCT_KEY.SKU, PRODUCT_KEY.DESC, PRODUCT_KEY.SUBTYPE, PRODUCT_KEY.GROUP];

  const searchedCells: CellType[] = [];

  items.forEach((c) => {
    searchableKeys.forEach((field) => {
      const value = c[field as keyof TableProductInfo]?.toString() ?? '';

      const isMatched = !!getRegexpMatching(search, value);

      if (isMatched) {
        const cell: CellType = { id: c.id, field, value };
        searchedCells.push(cell);
      }
    });
  });

  return searchedCells;
};

export const saveTabRouteToLS = (id: string, route: string) => {
  const tabs: TabItem[] = localStorageService.tabs;
  const newTabs = tabs.map((t) => (t.id === id ? { ...t, route } : t));
  localStorageService.tabs = newTabs;
};

export const isTableRowEmpty = (product: TableProductInfo, isDistributionCurveType: boolean) => {
  const ignoredKeys = [PRODUCT_KEY.ID, ...(isDistributionCurveType ? [PRODUCT_KEY.ORIENT] : [])];

  for (const key of Object.keys(product) as Array<keyof TableProductInfo>) {
    if (!ignoredKeys.includes(key as PRODUCT_KEY) && product[key]) {
      return false;
    }
  }
  return true;
};

export const addMissingTableRows = (
  catalog: CatalogItemState,
  copiedRowsNumber: number,
  rowsCount: number,
  startPasteIdx: number
) => {
  const rowsCountToTableEnd = rowsCount - startPasteIdx;
  const shouldAddNewRows = rowsCountToTableEnd < copiedRowsNumber;

  if (!shouldAddNewRows) return { newItemIDs: {}, visibleItems: {} };

  const numberOfNewRowsNeeded = copiedRowsNumber - rowsCountToTableEnd;
  const { loadedType } = catalog;

  const newItemIDs: Record<string, boolean> = {};
  const visibleItems: Record<string, TableProductInfo> = {};

  for (let i = 0; i < numberOfNewRowsNeeded; i++) {
    const newItem: TableProductInfo = getNewEmptyProduct(loadedType);
    newItemIDs[newItem.id] = true;
    visibleItems[newItem.id] = newItem;
  }

  return { newItemIDs, visibleItems };
};

export const pasteProductsCopiedCells = (
  catalog: CatalogItemState,
  products: TableProductInfo[],
  copiedCells: CellType[],
  updatedKeys: string[],
  areCopiedMultipleRows: boolean
) => {
  const copiedRows = getCopiedRowsFromCells(copiedCells, updatedKeys);

  // if only 1 row is copied, it should be pasted into all the selected rows
  // if multiple rows are copied, only copied amount of rows should be pasted
  const updatedProducts = products.map((item, idx) => {
    const copiedRowKey = areCopiedMultipleRows ? idx.toString() : '0';
    const changedProductProperties = catalog.changedItems[item.id];
    const initialProduct: TableProductInfo = changedProductProperties ?? { id: item.id };

    const updatedProduct = getProductWithPastedValues(initialProduct, copiedRows, updatedKeys, copiedRowKey);

    catalog.changedItems[item.id] = updatedProduct;
    return updatedProduct;
  });

  const changedItems = Object.fromEntries(updatedProducts.map((product) => [product.id, product]));

  return { updatedProducts, changedItems };
};

type RowCells = Map<string, string | number | null>;
const getCopiedRowsFromCells = (copiedCells: CellType[], updatedColumns: string[]) => {
  const copiedRows: Map<string, RowCells> = new Map();

  copiedCells.forEach(({ id, field, value }) => {
    const currentColumn = updatedColumns[Number(field)];
    if (!currentColumn) return;

    if (!copiedRows.has(id)) copiedRows.set(id, new Map());

    copiedRows.get(id)?.set(currentColumn, value);
  });

  return copiedRows;
};

const getProductWithPastedValues = (
  initialProduct: TableProductInfo,
  copiedRows: Map<string, RowCells>,
  updatedKeys: string[],
  copiedRowKey: string
) => {
  const updatedProduct = updatedKeys.reduce((updatedProduct, key) => {
    let updatedValue = copiedRows.get(copiedRowKey)?.get(key);

    if (updatedValue) {
      const isNumericColumn = key.startsWith('place') || NUMERIC_KEYS.includes(key as PRODUCT_KEY);
      if (isNumericColumn) {
        // extract only numeric part from a string for numeric fields
        updatedValue = extractNumberFromString(updatedValue.toString());
      }
    }

    return { ...updatedProduct, [key]: updatedValue || null };
  }, initialProduct);

  return updatedProduct;
};
