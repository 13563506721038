export function separateByUpperCase(inputString: string) {
  const regex = /[A-Z]/g;

  const resultArray = inputString
    .split('')
    .map((s) => (regex.test(s) ? ` ` + s : s))
    .join('');

  return resultArray;
}

export const extractNumberFromString = (str: string) => {
  const match = str.match(/\d+(\.\d+)?/g);
  return match ? match[0] : '';
};
