import { Typography, TypographyProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { SearchData, SearchType } from 'shared/hooks';
import { getDividedByRegexpText } from 'shared/lib';

type Props = TypographyProps & {
  value?: string;
  searchData: SearchData;
  searchType: SearchType;
};

export const HighlightedText: FC<Props> = ({
  value,
  searchData: { searchQuery, currentMatchIdx, matches },
  searchType,
  sx,
  ...props
}) => {
  const highlightColor = useMemo(() => {
    if (!value || !matches.length) return '';

    const matchIndex = matches.findIndex((m) => m === value);
    if (matchIndex === -1) return '';

    // current match
    if (matchIndex === currentMatchIdx) return 'rgba(255, 150, 50, 0.8)';
    // regular match
    return 'rgba(253, 221, 86, 0.7)';
  }, [value, matches, currentMatchIdx]);

  const highLightedTextParts = useMemo(() => {
    if (!highlightColor || !value) return null;
    return getDividedByRegexpText(searchQuery, value);
  }, [highlightColor, searchQuery, value]);

  return (
    <Typography
      {...props}
      id={`${value}-${searchType}`}
      noWrap
      sx={{
        span: {
          backgroundColor: highlightColor,
        },
        ...sx,
      }}
    >
      {highLightedTextParts && highLightedTextParts.length
        ? highLightedTextParts.map((textPart, index) =>
            index === 1 ? <span key={`${textPart}-${index}`}>{textPart}</span> : textPart
          )
        : value}
    </Typography>
  );
};
