import { IconButton, InputBase, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, KeyboardEvent } from 'react';
import { ArrowDown, Icon, SearchIcon } from 'shared/ui';

type Props = {
  searchQuery: string;
  matchesCount: number;
  currentMatchIdx: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  goToMatch: (direction: 'prev' | 'next') => void;
  onClear: () => void;
  smallInput?: boolean;
  inputId?: string;
};

export const TableSearchInput: FC<Props> = ({
  searchQuery,
  matchesCount,
  currentMatchIdx,
  onChange,
  goToMatch,
  onClear,
  smallInput,
  inputId,
}) => {
  const disableArrow = matchesCount === 1;
  const color = disableArrow ? 'rgba(114, 114, 114, 0.7)' : 'rgba(114, 114, 114, 1)';

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.code == 'Enter') {
      e.preventDefault();
      goToMatch('next');
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        zIndex: 2,
        ...(smallInput && { justifyContent: 'flex-end', width: '100%' }),
      }}
    >
      <InputBase
        id={inputId}
        value={searchQuery}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Find..."
        startAdornment={<SearchIcon sx={{ fontSize: 16, mr: 1 }} />}
        endAdornment={
          matchesCount ? (
            <Stack direction="row" alignItems="center">
              <Typography sx={{ color: color }}>{`(${currentMatchIdx + 1}/${matchesCount})`}</Typography>

              <IconButton
                onClick={() => goToMatch('next')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0 }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
              <IconButton
                onClick={() => goToMatch('prev')}
                disabled={disableArrow}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ p: 0, rotate: '180deg' }}
              >
                <ArrowDown width="32" height="32" viewBox="-5 -6.5 21 21" fill={color} />
              </IconButton>
            </Stack>
          ) : null
        }
        sx={{
          p: 1,
          width: 360,
          height: 40,
          borderRadius: '6px',
          border: '1px solid #DDE0E6',
          bgcolor: '#FFF',
          '& .MuiInputBase-input': {
            p: 0,
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#757575',
            opacity: 1,
          },
          transition: 'width 0.3s ease-in-out',
          ...(smallInput && {
            width: searchQuery.length ? '100%' : 100,
            maxWidth: 320,
            height: 33,
            '&:focus-within': {
              width: '100%',
            },
          }),
        }}
      />

      {searchQuery && (
        <IconButton onClick={onClear} sx={{ width: 40, height: 40 }}>
          <Icon path="products-view/search-close" />
        </IconButton>
      )}
    </Stack>
  );
};
