import { FC } from 'react';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { Button, Icon } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { Brand, Group, TableProductInfo } from 'shared/models';
import { TableTabs } from './TableTabs';
import { useChangeProductsControl } from '../hooks';

type Props = {
  disabled: boolean;
  type: string;
  specification: number;
  items: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  isDistributionCurveType: boolean;
  isSaveLoading: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  groups: Group[];
  brands: Brand[];
  onAddItem: () => void;
};

const buttonStyles = {
  py: 1,
  px: 2,
  gap: 1,
  borderRadius: '6px',
};

const buttonTextStyles = {
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '0.18px',
};

export const ChangeProductsControl: FC<Props> = ({
  disabled,
  type,
  specification,
  items,
  changedItems,
  isEditing,
  deletedItemIDs,
  newItemIDs,
  isDistributionCurveType,
  isSaveLoading,
  cellErrors,
  groups,
  brands,
  onAddItem,
}) => {
  const { t } = useTranslation();

  const {
    validationLoading,
    allViewChangedItemsAmount,
    onOpenCatalogFinalUpdates,
    onCancelClick,
    handSaveChanges,
  } = useChangeProductsControl({
    type,
    items,
    changedItems,
    deletedItemIDs,
    newItemIDs,
    isDistributionCurveType,
    cellErrors,
    groups,
    brands,
  });

  const isSaveButtonLoading = validationLoading || (isDistributionCurveType && isSaveLoading);

  return (
    <Box sx={{ bgcolor: '#FFF' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ py: isEditing ? 1 : 2, px: 3 }}>
        <TableTabs
          disabled={disabled}
          type={type}
          specification={specification}
          isDistributionCurveType={isDistributionCurveType}
        />

        <Stack direction="row" alignItems="center" spacing={1} sx={{ height: isEditing ? '100%' : 48 }}>
          <Icon path="products-view/catalog-total-items" />

          <Typography
            noWrap
            sx={{ fontSize: 18, fontWeight: 600, lineHeight: '130%', letterSpacing: '0.18px', color: '#242731' }}
          >
            {`${t('total_items')}: ${items?.length}`}
          </Typography>
        </Stack>
      </Stack>

      {isEditing && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 1, px: 3, borderBlock: '1px solid #D4D4D4' }}
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <ButtonBase
              onClick={onAddItem}
              sx={{ ...buttonStyles, border: '1px solid #DDE0E6', bgcolor: '#344054' }}
            >
              <Icon path={'products-view/add-new-item'} />

              <Typography sx={{ fontWeight: 600, lineHeight: '130%', textTransform: 'capitalize', color: '#FFF' }}>
                {t('new_item')}
              </Typography>
            </ButtonBase>

            {!!allViewChangedItemsAmount && (
              <ButtonBase
                onClick={onOpenCatalogFinalUpdates}
                sx={{
                  ...buttonStyles,
                  border: '1px solid rgba(29, 135, 69, 0.16)',
                  bgcolor: 'rgba(29, 135, 66, 0.04)',
                }}
              >
                <Icon path="products-view/catalog-view-changes" />

                <Typography noWrap sx={{ ...buttonTextStyles, color: '#1D8742', fontWeight: 400 }}>
                  {t('View Changes')}
                  <Box component="span" sx={{ ml: 1, fontWeight: 600 }}>
                    {allViewChangedItemsAmount}
                  </Box>
                </Typography>
              </ButtonBase>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" spacing={3}>
            <ButtonBase
              onClick={onCancelClick}
              disabled={isSaveButtonLoading}
              sx={{ py: 1, color: '#727272', ...buttonTextStyles }}
            >
              {t('cancel')}
            </ButtonBase>

            <Button
              type="button"
              onClick={handSaveChanges}
              loading={isSaveButtonLoading}
              disabled={!allViewChangedItemsAmount}
              sx={{
                py: 1,
                px: 4,
                borderRadius: '8px',
                bgcolor: 'primary.main',
                color: '#FFF',
                '&:hover': { bgcolor: 'primary.main' },
                '&:disabled': { color: 'info.light', opactity: 0.8 },
                ...buttonTextStyles,
              }}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
