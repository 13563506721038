import { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import scrollIntoView from 'scroll-into-view-if-needed';

export type SearchResults = {
  matches: string[];
  currentMatchIdx: number;
};

export type SearchData = SearchResults & { searchQuery: string };

export type SearchType = 'description' | 'translation' | 'availability-product';

const containerIds: Record<SearchType, string> = {
  description: 'scrollable-translations-container',
  translation: 'scrollable-translations-container',
  'availability-product': 'scrollable-availability-products-container',
};

const initSearchResults: SearchResults = { currentMatchIdx: 0, matches: [] };

export const useTableSearch = (type: SearchType, searchableItems: string[]) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResults>(initSearchResults);

  const { matches } = searchResults;
  const [debouncedQuery] = useDebounce(searchQuery, 300);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
  };

  const clearSearch = () => setSearchQuery('');

  useEffect(() => {
    if (!debouncedQuery.trim()) {
      setSearchResults(initSearchResults);
      return;
    }

    const foundMatches = searchableItems.filter((i) => i.toLowerCase().includes(debouncedQuery.toLowerCase()));

    if (foundMatches.length) scrollToElement(foundMatches[0]);

    setSearchResults((prev) => ({ ...prev, matches: foundMatches, currentMatchIdx: 0 }));
  }, [debouncedQuery, searchableItems]);

  const goToMatch = (direction: 'next' | 'prev') => {
    if (matches.length < 2) return;

    setSearchResults((prevData) => {
      const { currentMatchIdx, matches } = prevData;

      const nextIdx = currentMatchIdx === matches.length - 1 ? 0 : currentMatchIdx + 1;
      const prevIdx = currentMatchIdx === 0 ? matches.length - 1 : currentMatchIdx - 1;
      const newIndex = direction === 'next' ? nextIdx : prevIdx;

      const match = matches[newIndex - 1];
      if (match) scrollToElement(match);

      return {
        ...prevData,
        currentMatchIdx: newIndex,
      };
    });
  };

  const scrollToElement = (elementText: string) => {
    const element = document.getElementById(`${elementText}-${type}`);
    const container = document.getElementById(containerIds[type]);

    if (!element || !container) return;

    if (container) {
      scrollIntoView(element, {
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
        scrollMode: 'if-needed',
        boundary: container,
      });
    }
  };

  return { onSearchChange, searchQuery, searchResults, debouncedQuery, goToMatch, clearSearch };
};
