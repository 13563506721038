import { useMemo } from 'react';
import { StringResource, TableProductInfo } from 'shared/models';

type Args = {
  isProductsLoading: boolean;
  isGroupSelected: boolean;
  products: TableProductInfo[];
  selectedLanguageId: string;
};

export const useCatalogTranslationsTable = ({
  isProductsLoading,
  isGroupSelected,
  selectedLanguageId,
  products,
}: Args) => {
  const areSearchInputsShown = !isProductsLoading && isGroupSelected && products.length > 0;

  const descriptions = useMemo(() => {
    if (!areSearchInputsShown) return [];

    return products.map((p) => p.description).filter((desc): desc is string => Boolean(desc));
  }, [products, areSearchInputsShown]);

  const translations = useMemo(() => {
    if (!areSearchInputsShown) return [];
    if (!selectedLanguageId) return [];

    const stringResources = products
      .flatMap((p) => p.stringResources)
      .filter((sr): sr is StringResource => Boolean(sr));
    const currentTranslationItems = stringResources
      .filter((sr) => sr?.languageId === selectedLanguageId)
      .map((sr) => sr?.value);

    return currentTranslationItems;
  }, [products, selectedLanguageId, areSearchInputsShown]);

  return { areSearchInputsShown, descriptions, translations };
};
