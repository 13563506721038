import { FC, useEffect } from 'react';
import { ButtonBase, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/ui';
import { useCatalogSearchParams } from 'shared/hooks';
import { TableFilter } from 'shared/models';

const containerStyles = {
  gap: 1,
  paddingBlock: 1,
  paddingInline: 2,
  height: 37,
  borderRadius: '6px',
  border: '1px solid #DDE0E6',
  backgroundColor: '#FFF',
};

interface Props {
  filters: TableFilter[];
  onChangeFilters: (filters: TableFilter[]) => void;
}

export const CatalogFiltersPanel: FC<Props> = ({ filters, onChangeFilters }) => {
  const { t } = useTranslation();
  const { type } = useCatalogSearchParams();

  const isShowFilters = !!filters?.length;

  const onResetAllFilters = () => onChangeFilters([]);
  const onRemoveFilter = (field: string) => {
    const updatedFilters = filters.filter((f) => f.field !== field);
    onChangeFilters(updatedFilters);
  };

  useEffect(() => {
    onResetAllFilters();
  }, [type]);

  if (!isShowFilters) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ml: '0 !important',
      }}
    >
      <ButtonBase onClick={onResetAllFilters} sx={containerStyles}>
        <Icon path="products-view/clear-filters" />

        <Typography sx={{ fontWeight: 600, letterSpacing: '0.075px', lineHeight: '21px', color: '#242731' }}>
          {'Clear Filters'}
        </Typography>
      </ButtonBase>

      {filters.map(({ field }) => (
        <Stack key={field} direction="row" alignItems="center" sx={containerStyles}>
          <Typography sx={{ color: '#151518' }}>{t(field)}</Typography>

          <IconButton onClick={() => onRemoveFilter(field)} sx={{ p: 0 }}>
            <Icon path="products-view/filter-close" />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
};
