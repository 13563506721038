import { FC } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableProductInfo } from 'shared/models';
import { AvailabilityTableSelect, HighlightedText, Icon, ItemsBox, TableSearchInput } from 'shared/ui';
import { useCatalogAvailabilityProducts } from './hooks';
import { useTableSearch } from 'shared/hooks';

interface Props {
  isLoading: boolean;
  selectedProductIDs: string[];
  viewProducts: TableProductInfo[];
  selectedGroupName?: string;
  onToggleAll: () => void;
  onToggleProduct: (id: string) => void;
}

export const productsFilterOptions = {
  all: 'All Products',
  available: 'Available Products',
  not_available: 'Not Available Products',
};

export const CatalogAvailabilityProducts: FC<Props> = ({
  isLoading,
  selectedProductIDs,
  viewProducts,
  selectedGroupName,
  onToggleAll,
  onToggleProduct,
}) => {
  const { t } = useTranslation();

  const {
    filterValue,
    searchableItems,
    setFilterValue,
    filteredProducts,
    selectedProductItemsAmount,
    areAllProdcutsSelected,
  } = useCatalogAvailabilityProducts(viewProducts, selectedProductIDs);

  const { searchQuery, debouncedQuery, searchResults, onSearchChange, goToMatch, clearSearch } = useTableSearch(
    'availability-product',
    searchableItems
  );
  const { matches, currentMatchIdx } = searchResults;

  const isGroupSelected = Boolean(selectedGroupName);

  const checkboxControl = (
    <Checkbox
      icon={<Icon path="checkbox-green-icon" />}
      checkedIcon={<Icon path="checkbox-green-icon-checked" />}
      sx={{ mr: 1, width: 16, height: 16 }}
    />
  );

  return (
    <ItemsBox
      id="scrollable-availability-products-container"
      sx={{ flexGrow: 1, minWidth: 600, maxWidth: 766, maxHeight: 'calc(100svh - 236px - 60px)' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{
          position: 'sticky',
          top: 0,
          p: 1,
          pl: 1.5,
          height: 48,
          borderBottom: '0.5px solid #D4D4D4',
          bgcolor: '#fff',
          zIndex: 2,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography sx={{ fontWeight: 600, color: '#151518' }}>
            {selectedGroupName || t('Select Group')}
          </Typography>

          {isGroupSelected && (
            <Typography
              sx={{ fontWeight: 600, color: '#727272' }}
            >{`${selectedProductItemsAmount}/${viewProducts.length}`}</Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} width="100%">
          {!isGroupSelected ? (
            <Typography
              sx={{ fontWeight: 600, color: '#242731' }}
            >{`${selectedProductItemsAmount}/${viewProducts.length}`}</Typography>
          ) : (
            <TableSearchInput
              searchQuery={searchQuery}
              onChange={onSearchChange}
              matchesCount={matches.length}
              currentMatchIdx={currentMatchIdx}
              goToMatch={goToMatch}
              onClear={clearSearch}
              smallInput
            />
          )}

          <Box sx={{ width: '1px', height: 20, bgcolor: '#7B7D7B' }} />

          <AvailabilityTableSelect value={filterValue}>
            {Object.entries(productsFilterOptions).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
                onClick={() => setFilterValue(value as keyof typeof productsFilterOptions)}
              >
                {label}
              </MenuItem>
            ))}
          </AvailabilityTableSelect>
        </Stack>
      </Stack>

      {filteredProducts.length ? (
        <>
          <Stack
            justifyContent="center"
            sx={{ py: 1, pl: 2, pr: 2.5, minHeight: 38, borderBottom: '0.5px solid #D4D4D4' }}
          >
            <FormControlLabel
              checked={areAllProdcutsSelected}
              onChange={onToggleAll}
              label={
                <Typography noWrap sx={{ letterSpacing: '0.16px' }}>
                  {t('Select All')}
                </Typography>
              }
              control={checkboxControl}
              sx={{ m: 0 }}
            />
          </Stack>

          {filteredProducts.map(({ id, description }) => (
            <Stack
              key={id}
              justifyContent="center"
              sx={{
                py: 1,
                pl: 2,
                pr: 2.5,
                minHeight: 38,
                '&:not(:last-child)': { borderBottom: '0.5px solid #D4D4D4' },
              }}
            >
              <FormControlLabel
                key={id}
                checked={selectedProductIDs.includes(id)}
                onChange={() => onToggleProduct(id)}
                label={
                  <HighlightedText
                    noWrap
                    value={description}
                    searchData={{ ...searchResults, searchQuery: debouncedQuery }}
                    searchType="availability-product"
                    sx={{ letterSpacing: '0.16px' }}
                  />
                }
                control={checkboxControl}
                sx={{ m: 0 }}
              />
            </Stack>
          ))}
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 120 }}>
          {isLoading ? (
            <CircularProgress size={15} color="primary" sx={{ m: '9px' }} />
          ) : (
            <Typography sx={{ fontSize: 18, fontWeight: 600, letterSpacing: '0.18px', color: '#242731' }}>
              {t('Please select a group to view product list')}
            </Typography>
          )}
        </Stack>
      )}
    </ItemsBox>
  );
};
