import { ChangeEvent, FC, startTransition, useEffect, useState } from 'react';
import { useAppDispatch, useCatalogsControl, useCatalogSearchParams } from 'shared/hooks';
import { CellType } from 'shared/models';
import { setTableSearch, setTableSearchCurrentIdx } from 'shared/slices';
import { TableSearchInput } from 'shared/ui';
import { useDebounce } from 'use-debounce';

interface Props {
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
}

export const TableSearch: FC<Props> = ({ tableSearch }) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();
  const { items } = useCatalogsControl();

  const { search, currentIdx, cells } = tableSearch ?? { search: '', currentIdx: 0, cells: [] };

  const [inputValue, setInputValue] = useState(search);
  const [debouncedQuery] = useDebounce(inputValue, 300);

  const onChangeTableSearch = (value: string) => catalogId && dispatch(setTableSearch({ catalogId, value }));

  const onMoveToNewCurrentIdx = (moveTo: 'next' | 'prev') =>
    catalogId && dispatch(setTableSearchCurrentIdx({ catalogId, moveTo }));

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);

  const handleClearInputValue = () => setInputValue('');

  useEffect(() => {
    if (!debouncedQuery.trim()) {
      onChangeTableSearch('');
      return;
    }

    onChangeTableSearch(debouncedQuery);
  }, [debouncedQuery, items]);

  return (
    <TableSearchInput
      inputId="table-search-input"
      searchQuery={inputValue}
      onChange={handleChangeInputValue}
      matchesCount={cells.length}
      currentMatchIdx={currentIdx}
      goToMatch={onMoveToNewCurrentIdx}
      onClear={handleClearInputValue}
    />
  );
};
