import { CircularProgress, Stack, Typography } from '@mui/material';
import { AvailabilityOrgItem } from 'entities/Organization/components/AvailabilityOrgItem';
import { OrgTitle } from 'entities/Organization/components/AvailabilityOrgItem/OrgTitle';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Organization } from 'shared/models';
import { ItemsBox } from 'shared/ui';

interface Props {
  currentOrgId: string;
  onSelectOrg: (id: string) => void;
  isLoading: boolean;
}

export const CatalogAvailabilityOrgsTable: FC<Props> = ({ currentOrgId, onSelectOrg, isLoading }) => {
  const { t } = useTranslation();

  const { organizations } = useAppSelector((st) => st.orgs);

  const renderOrgs = (orgs: Organization[], depth = 0): ReactNode => {
    return orgs.map((org, i) => {
      if (org.subOrganizations.length === 0) {
        return (
          <OrgTitle
            key={org.id}
            org={org}
            depth={depth}
            currentOrgId={currentOrgId}
            onSelectOrg={onSelectOrg}
            disabled={isLoading}
          />
        );
      }
      return (
        <AvailabilityOrgItem
          key={org.id}
          org={org}
          depth={depth}
          childOrgs={renderOrgs(org.subOrganizations, depth + 1)}
          isLastChild={orgs.length - 1 === i}
          currentOrgId={currentOrgId}
          onSelectOrg={onSelectOrg}
          disabled={isLoading}
          childIDs={org.subOrganizations.map((o) => o.organizationId)}
        />
      );
    });
  };

  return (
    <ItemsBox sx={{ width: '280px', minWidth: '280px', maxHeight: 'calc(100svh - 236px - 60px)' }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          position: 'sticky',
          top: 0,
          p: 1.5,
          height: 48,
          zIndex: 2,
          borderBottom: '0.5px solid #D4D4D4',
          bgcolor: '#FFF',
        }}
      >
        <Typography
          sx={{
            color: isLoading ? 'info.dark' : 'darksome.main',
            fontWeight: 600,
            textTransform: 'capitalize',
          }}
        >
          {t('Select Organization')}
        </Typography>

        {isLoading && <CircularProgress size={15} color="primary" />}
      </Stack>

      {renderOrgs(organizations)}
    </ItemsBox>
  );
};
