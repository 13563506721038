import { DataGridPremium, GRID_CHECKBOX_SELECTION_FIELD, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Checkbox } from '@mui/material';
import { CatalogSelectionArrow, TableArrowScrolling } from '../../shared/ui';
import { Brand, CellType, Group, TableProductInfo } from 'shared/models';
import { PRODUCT_KEY } from 'shared/constants';
import { getDividedByRegexpText, getRegexpMatching } from 'shared/lib';
import { ContextMenuWrapper, RenderCell } from './components';
import { memo, MutableRefObject } from 'react';
import { useParams } from 'react-router-dom';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { usePersistTableScroll } from '../../shared/hooks/usePersistTableScroll';
import { ExtendedGridColDef } from './GridContainer';
import { useDataGrid } from './hooks';

const ROW_HEIGHT = 30;

const searchableColumns: string[] = [PRODUCT_KEY.SKU, PRODUCT_KEY.DESC, PRODUCT_KEY.SUBTYPE, PRODUCT_KEY.GROUP];

type Props = {
  columns: ExtendedGridColDef[];
  specification: number;
  items: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  isValidating: boolean;
  onToggleValidating: () => void;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  isDistributionCurveType: boolean;
  catalogTypeGroups: Group[];
  catalogBrands: Brand[];
  tableRef: MutableRefObject<GridApiPremium>;
};

export const MemoGrid = memo(
  ({
    tableRef: apiRef,
    items,
    isEditing,
    specification,
    columns,
    newItemIDs,
    changedItems,
    deletedItemIDs,
    tableSearch,
    cellErrors,
    isValidating,
    isDistributionCurveType,
    catalogTypeGroups,
    onToggleValidating,
  }: Props) => {
    const { id: catalogId } = useParams();

    usePersistTableScroll(apiRef, catalogId);

    const {
      isSelectedFullRow,
      handleOpenCellEditMode,
      handleCellEditStart,
      handleCellEditStop,
      checkIsCellEditable,
      handleCellSelectionModelChange,
      handleRowSelectionModelChange,
      handleCellClick,
      handleColumnWidthChange,
      handleCellDoubleClick,
      handleHorizontalScroll,
      getCellClassName,
      handleSaveSelectedCells,
      menuHeight,
      menuPosition,
    } = useDataGrid({
      apiRef,
      catalogId,
      items,
      isEditing,
      specification,
      columns,
      newItemIDs,
      changedItems,
      deletedItemIDs,
      tableSearch,
      cellErrors,
      isValidating,
      isDistributionCurveType,
      catalogTypeGroups,
      onToggleValidating,
      ROW_HEIGHT,
    });

    return (
      <>
        <ContextMenuWrapper isSelectedFullRow={isSelectedFullRow} openEditMode={handleOpenCellEditMode}>
          <DataGridPremium
            apiRef={apiRef}
            initialState={{
              pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD, PRODUCT_KEY.SKU, PRODUCT_KEY.DESC] },
            }}
            rowHeight={ROW_HEIGHT}
            columnHeaderHeight={35}
            hideFooter
            columns={columns}
            rows={items}
            unstable_cellSelection={true}
            checkboxSelection={true}
            rowSelection={true}
            disableRowSelectionOnClick
            experimentalFeatures={{ clipboardPaste: true }}
            slotProps={{
              cell: {
                onContextMenu: handleSaveSelectedCells,
              },
              baseSelect: {
                MenuProps: {
                  id: 'singleSelectMenu',
                  PaperProps: {
                    sx: {
                      maxHeight: menuHeight,
                      top: `${menuPosition}px !important`,
                    },
                  },
                },
              },
            }}
            slots={{
              baseCheckbox: (props) => (
                <Checkbox
                  {...props}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  checkedIcon={<CatalogSelectionArrow sx={{ width: 10, color: 'darksome.main', ml: -1 }} />}
                  icon={<CatalogSelectionArrow sx={{ opacity: 0 }} />}
                />
              ),
              cell: (params: GridRenderCellParams) => {
                const { field, value } = params;

                const id = (params as unknown as { rowId: string }).rowId;
                const isChanged = changedItems[id] ? params.field in changedItems[id] : false;

                const { search, currentIdx, cells } = tableSearch;

                const isHightLight =
                  searchableColumns.includes(field) && search && !!getRegexpMatching(search, value ?? '');

                const highLightedTextParts: string[] | null = isHightLight
                  ? getDividedByRegexpText(search, value ?? '')
                  : null;
                const isHighlightedOnRed =
                  !!cells.length && cells[currentIdx].id === id && cells[currentIdx].field === params.field;

                return (
                  <RenderCell
                    {...params}
                    isChangedCell={isChanged}
                    highLightedTextParts={highLightedTextParts}
                    isHighlightedOnRed={isHighlightedOnRed}
                  />
                );
              },
            }}
            getRowClassName={(params) => {
              const id = params.id.toString();
              if (deletedItemIDs[id]) return 'deleted_row';
              if (newItemIDs[id]) return 'new_row';
              return '';
            }}
            getCellClassName={(params) => {
              const { id, field } = params;
              return getCellClassName(id.toString(), field);
            }}
            onCellEditStart={(params) => {
              const { id, field } = params;
              handleCellEditStart(id, field);
            }}
            onCellEditStop={(params) => {
              const { id, field, value, colDef } = params;
              handleCellEditStop(id.toString(), field, value, colDef.type);
            }}
            isCellEditable={(params) => {
              const id = params.id.toString();
              return checkIsCellEditable(id, params.field);
            }}
            unstable_onCellSelectionModelChange={handleCellSelectionModelChange}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            onCellClick={(params, e) => {
              const { field, id, colDef } = params;
              handleCellClick(e, field, id, colDef.type);
            }}
            onColumnWidthChange={(params) => {
              const {
                colDef: { field },
                width,
              } = params;
              handleColumnWidthChange(field, width);
            }}
            onCellDoubleClick={handleCellDoubleClick}
          />
        </ContextMenuWrapper>

        <TableArrowScrolling
          isEditing={isEditing}
          onScrollToLeft={() => handleHorizontalScroll('left')}
          onScrollToRight={() => handleHorizontalScroll('right')}
        />
      </>
    );
  }
);
