import { FC } from 'react';
import { useTranslations } from '../../hooks';
import { Stack } from '@mui/material';
import { CatalogTranslationsTable, TypeSubtypeGroupFilters } from 'features';
import { scrollStyles } from 'widgets/CatalogManager/utils';
import { GroupsTable } from './GroupsTable';

export const CatalogTranslations: FC = () => {
  const {
    groupItems,
    groupItemsExtended,
    languages,
    isLanguagesLoading,
    selectedGroupId,
    selectedLanguageId,
    onLanguageChange,
    onGroupChange,
    selectedTranslationId,
    onTranslationItemSelect,
    catalogTypes,
    type,
    onChangeManageType,
    filteredProducts,
    updateFilteredProduct,
    isProductsLoading,
    setActiveBox,
    onKeyDownWithScroll,
    selectedSubtypeId,
    setSelectedSubtypeId,
    subtypeItems,
  } = useTranslations();

  const subtypeFilterValue = selectedSubtypeId ? subtypeItems.find((st) => st.id === selectedSubtypeId)?.name : '';
  const groupFilterValue = selectedGroupId ? groupItems.find((g) => g.id === selectedGroupId)?.name : '';

  return (
    <Stack sx={{ height: '100%' }}>
      <TypeSubtypeGroupFilters
        type={type}
        catalogTypes={catalogTypes}
        onTypeChange={onChangeManageType}
        subtypeFilterValue={subtypeFilterValue}
        subtypeItems={subtypeItems}
        onChangeSubtype={setSelectedSubtypeId}
        groupFilterValue={groupFilterValue}
        groupItems={groupItems}
        onChangeGroup={onGroupChange}
      />

      <Stack
        direction="row"
        spacing={5}
        sx={{
          position: 'relative',
          pb: 2.5,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          ...scrollStyles,
        }}
      >
        <GroupsTable
          selectedGroupId={selectedGroupId}
          groupItems={groupItemsExtended}
          onGroupChange={onGroupChange}
          onKeyDown={onKeyDownWithScroll}
          setActiveBox={() => setActiveBox('group')}
        />

        <CatalogTranslationsTable
          isLanguagesLoading={isLanguagesLoading}
          isProductsLoading={isProductsLoading}
          isGroupSelected={Boolean(selectedGroupId)}
          selectedLanguageId={selectedLanguageId}
          selectedTranslationId={selectedTranslationId}
          languages={languages}
          products={filteredProducts}
          updateProduct={updateFilteredProduct}
          onLanguageChange={onLanguageChange}
          onSelect={onTranslationItemSelect}
          onKeyDown={onKeyDownWithScroll}
        />
      </Stack>
    </Stack>
  );
};
