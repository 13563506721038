import {
  Box,
  ButtonBase,
  InputBase,
  MenuItem,
  Paper,
  Popover,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Group, SubType } from 'shared/models';
import { Icon } from 'shared/ui';
import { scrollStyles } from 'widgets/CatalogManager/utils';

type Props<T> = SelectProps & {
  label: string;
  allowEmpty?: boolean;
  clearFilter?: () => void;
  options: T[];
  renderOption?: (option: T) => string;
  renderMenuItem: (option: T) => ReactNode;
};

export const FilterSelect = <T extends string | SubType | Group>({
  label,
  value,
  allowEmpty,
  clearFilter,
  options,
  renderOption = (option) => option as string,
  renderMenuItem,
  disabled,
}: Props<T>) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectRef = useRef<HTMLButtonElement>(null);

  const isSelectedOption = Boolean(value);
  const isClearButtonShown = isSelectedOption && allowEmpty;

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const filterOptions = (query: string) => {
    const newOptions = options.filter((option) =>
      renderOption(option).toLowerCase().includes(query.trim().toLowerCase())
    );
    setFilteredOptions(newOptions);
  };

  return (
    <Stack direction="row" alignItems="stretch" spacing={0.5}>
      <Stack
        component="button"
        onClick={() => setIsMenuOpen(true)}
        disabled={disabled}
        ref={selectRef}
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          py: '10px',
          px: '20px',
          borderRadius: '16px',
          border: '1px solid #D4D4D4',
          background: '#F2F4F7',
          cursor: 'pointer',
          ...(isSelectedOption && {
            borderColor: 'rgba(29, 135, 69, 0.16)',
            background: '#ECFDF3',
          }),
          ...(isClearButtonShown && {
            pr: 1,
            borderRadius: '16px 0px 0px 16px',
          }),
        }}
      >
        <Typography sx={{ color: isSelectedOption ? '#027A48' : '#344054', fontWeight: 600 }}>
          {value ? `${label}: ${value}` : label}
        </Typography>

        <Icon
          path={`products-view/select-arrow-${isSelectedOption ? 'green' : 'black'}`}
          sx={{ ...(isMenuOpen && { transform: 'rotate(180deg)' }) }}
        />
      </Stack>

      {isClearButtonShown && (
        <ButtonBase
          onClick={clearFilter}
          sx={{
            px: '18px',
            borderRadius: '0px 16px 16px 0px',
            border: '1px solid rgba(29, 135, 69, 0.16)',
            background: '#ECFDF3',
          }}
        >
          <Icon path="products-view/select-close" />
        </ButtonBase>
      )}

      <Popover
        open={isMenuOpen}
        anchorEl={selectRef.current}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ mt: 0.5 }}
      >
        <Paper
          sx={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <InputBase
            placeholder="Search..."
            onChange={(e) => filterOptions(e.target.value)}
            sx={{
              width: '100%',
              py: 1,
              px: 2,
              borderBottom: '1px solid #E4E2E4',
              '& .MuiInputBase-input': {
                p: 0,
                '&::placeholder': {
                  color: '#242731',
                  opacity: 1,
                },
              },
            }}
            startAdornment={<Icon path="products-view/search" sx={{ mr: 1 }} />}
          />

          {filteredOptions?.length ? (
            <Box
              onClick={() => setIsMenuOpen(false)}
              sx={{ maxHeight: 'calc(100vh - 230px)', overflowY: 'auto', ...scrollStyles }}
            >
              {filteredOptions.map((option) => renderMenuItem(option))}
            </Box>
          ) : (
            <MenuItem disabled>No Data</MenuItem>
          )}
        </Paper>
      </Popover>
    </Stack>
  );
};
