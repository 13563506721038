import { Box, Stack, styled, Typography } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { useManageGroups } from '../../hooks';
import { selectCatalogsLoading, selectCatalogTypes, selectCurrentCatalog } from '../../../../shared/slices';
import { CatalogPermission } from '../../../../shared/enum';
import { Filters } from './Filters';
import { SubtypesTable } from './SubtypesTable';
import { GroupsTable } from './GroupsTable';
import { scrollStyles } from 'widgets/CatalogManager/utils';

export const BoxHeader = styled(Typography)(() => ({
  padding: '12px',
  fontWeight: 600,
  textTransform: 'capitalize',
}));

export const GroupsSubtypesBrands = () => {
  const catalogTypes = useAppSelector(selectCatalogTypes);
  const isCatalogLoading = useAppSelector(selectCatalogsLoading);

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const isViewer = currentCatalog.shared?.permission?.includes(CatalogPermission.Read);
  const isNoTypes = !catalogTypes.length;
  const disabled = isCatalogLoading || isViewer;

  const {
    type,
    onChangeManageType,
    subtypeItems,
    loadingIDs,
    onChangeSubtype,
    onDeleteSubtype,
    onCreateSubtype,
    onCreateGroup,
    onDeleteGroup,
    onChangeGroup,
    selectedIDs,
    onChangeSelectedIDs,
    isGroupsDisabled,
    filteredGroups,
    setActiveBox,
    onKeyDownWithScroll,
  } = useManageGroups();

  const subtypeFilterValue = selectedIDs.subtype
    ? subtypeItems.find((st) => st.id === selectedIDs.subtype)?.name
    : '';

  return (
    <Stack sx={{ height: '100%' }}>
      <Filters
        isNoTypes={isNoTypes}
        type={type}
        catalogTypes={catalogTypes}
        onChangeManageType={onChangeManageType}
        subtypeFilterValue={subtypeFilterValue}
        onChangeSelectedIDs={onChangeSelectedIDs}
        subtypeItems={subtypeItems}
      />

      <Stack
        direction="row"
        spacing={5}
        sx={{
          position: 'relative',
          pb: 2.5,
          width: '100%',
          height: '100%',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          ...scrollStyles,
        }}
      >
        <SubtypesTable
          isPipeType={type === 'Pipe'}
          isViewer={isViewer}
          selectedIDs={selectedIDs}
          disabled={disabled}
          loadingIDs={loadingIDs}
          subtypeItems={subtypeItems}
          onChangeSelectedIDs={(id: string) => onChangeSelectedIDs(id, 'subtype')}
          onCreateSubtype={onCreateSubtype}
          onDeleteSubtype={onDeleteSubtype}
          onChangeSubtype={onChangeSubtype}
          setActiveBox={() => setActiveBox('subtype')}
          onKeyDown={onKeyDownWithScroll}
        />

        <GroupsTable
          isViewer={isViewer}
          selectedIDs={selectedIDs}
          disabled={disabled}
          isGroupsDisabled={isGroupsDisabled}
          loadingIDs={loadingIDs}
          subtypeItems={subtypeItems}
          groupItems={filteredGroups}
          onChangeSelectedIDs={(id: string) => onChangeSelectedIDs(id, 'group')}
          onChangeGroup={onChangeGroup}
          onCreateGroup={onCreateGroup}
          onDeleteGroup={onDeleteGroup}
          setActiveBox={() => setActiveBox('group')}
          onKeyDown={onKeyDownWithScroll}
        />

        <Box
          onClick={() => onChangeSelectedIDs('')}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 1,
            ml: '0 !important',
          }}
        />
      </Stack>
    </Stack>
  );
};
