import { MenuItem, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { separateByUpperCase } from 'shared/lib';
import { Group, SubType } from 'shared/models';
import { FilterSelect } from 'shared/ui';

type Props = {
  type: string;
  catalogTypes: string[];
  onTypeChange: (type: string) => void;
  subtypeFilterValue?: string;
  subtypeItems: SubType[];
  onChangeSubtype: (subtypeId: string) => void;
  groupFilterValue?: string;
  groupItems: Group[];
  onChangeGroup: (groupId: string) => void;
};

export const TypeSubtypeGroupFilters: FC<Props> = ({
  type,
  catalogTypes,
  onTypeChange,
  subtypeFilterValue,
  subtypeItems,
  onChangeSubtype,
  groupFilterValue,
  groupItems,
  onChangeGroup,
}) => {
  const { t } = useTranslation();

  const isNoTypes = !catalogTypes.length;

  return (
    <Stack direction="row" spacing={3} sx={{ py: 3 }}>
      <FilterSelect
        label={t('type')}
        value={isNoTypes ? '' : type}
        options={catalogTypes}
        renderMenuItem={(type: string) => (
          <MenuItem key={type} value={type} onClick={() => onTypeChange(type)}>
            {separateByUpperCase(type)}
          </MenuItem>
        )}
      />

      <FilterSelect<SubType>
        label={t('subtype')}
        value={subtypeFilterValue ?? ''}
        clearFilter={() => onChangeSubtype('')}
        allowEmpty
        options={subtypeItems}
        renderOption={(subtype: SubType) => subtype.name}
        renderMenuItem={({ id, name }: SubType) => (
          <MenuItem key={id} value={name} onClick={() => onChangeSubtype(id)}>
            {name}
          </MenuItem>
        )}
      />

      <FilterSelect<Group>
        label={t('group')}
        value={groupFilterValue ?? ''}
        clearFilter={() => onChangeGroup('')}
        allowEmpty
        options={groupItems}
        renderOption={(group: Group) => group.name}
        renderMenuItem={({ id, name }: Group) => (
          <MenuItem key={id} value={name} onClick={() => onChangeGroup(id)}>
            {name}
          </MenuItem>
        )}
      />
    </Stack>
  );
};
