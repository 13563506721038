import { FC, KeyboardEvent } from 'react';
import { HoverToolTip, ItemsBox } from 'shared/ui';
import { BoxHeader } from '../GroupsSubtypesBrands';
import { ButtonBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GroupExtended } from 'widgets/CatalogManager/hooks';

type Props = {
  selectedGroupId: string;
  groupItems: GroupExtended[];
  disabled?: boolean;
  onGroupChange: (groupId: string) => void;
  onKeyDown: (e: KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => void;
  setActiveBox: () => void;
};

export const GroupsTable: FC<Props> = ({
  selectedGroupId,
  groupItems,
  disabled,
  onGroupChange,
  onKeyDown,
  setActiveBox,
}) => {
  const { t } = useTranslation();

  return (
    <ItemsBox id="scrollable-groups-container" sx={{ flexGrow: 1, minWidth: 240, maxWidth: 320 }}>
      <BoxHeader
        sx={{
          position: 'sticky',
          top: 0,
          height: 48,
          zIndex: 2,
          bgcolor: '#FFF',
          borderBottom: '0.5px solid #D4D4D4',
        }}
      >
        {t('Select Group')}
      </BoxHeader>

      {groupItems.map(({ id, name, productsCount }) => (
        <ButtonBase
          key={id}
          id={id}
          disabled={disabled}
          onClick={() => {
            onGroupChange(id);
            setActiveBox();
          }}
          onKeyDown={onKeyDown}
          tabIndex={0}
          sx={{
            justifyContent: 'space-between',
            gap: 1,
            py: 1,
            pl: 1.5,
            pr: 2.5,
            height: 38,
            textTransform: 'capitalize',
            ...(selectedGroupId === id && { bgcolor: 'rgba(29, 135, 69, 0.08) !important' }),
            '&:not(:last-child)': { borderBottom: '0.5px solid #D4D4D4' },
            '&:hover': { bgcolor: 'rgba(29, 135, 69, 0.04)' },
          }}
        >
          <Typography noWrap sx={{ color: '#242731' }}>
            <HoverToolTip title={name} placement="top-start">
              <span>{name}</span>
            </HoverToolTip>
          </Typography>

          <Typography noWrap sx={{ flexShrink: 0, fontWeight: 600, color: '#242731' }}>
            {productsCount}
          </Typography>
        </ButtonBase>
      ))}
    </ItemsBox>
  );
};
